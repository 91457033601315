import { useEffect, useMemo, useState } from 'react'
import { getImage } from '@dis/api/src/file'
import { AxiosInstances } from '@dis/api'
import { hash } from 'object-code'
import { Buffer } from 'buffer'

export const useImageData = (url: string, priority = 0) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  const [iterator, setIterator] = useState(1)

  useEffect(() => {
    if (url) {
      const timeoutRef = setTimeout(() => {
        getImage({ url }).then(
          () => {
            setIterator(Date.now())
          },
          (error) => {
            console.error('useImageData error: ', error)
          },
        )
      }, priority * 50)

      return () => {
        clearTimeout(timeoutRef)
      }
    }
  }, [url, priority])

  const data = useMemo(() => {
    if (url && iterator) {
      const id = hash({ url }).toString()

      const cachedData = AxiosInstances.axiosGetImageInstance.storage?.data?.[id]?.data
        ?.data as ArrayBuffer

      if (cachedData) {
        return `data:image/png;base64,${Buffer.from(cachedData).toString('base64')}`
      }
    }

    return ''
  }, [url, iterator])

  return data
}
