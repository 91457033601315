import { CHANNEL_ICONS } from '@dis/modules/src/channelManagement/constants'

export enum ChannelEnum {
  GlobalChannel = 'global',
  LocalChannel = 'local',
}

export type ManagementChannel = {
  code: string
  description: string
  icon: keyof typeof CHANNEL_ICONS
  id: number
  name: string
  published: boolean
  type: ChannelEnum
}

export type JourneyChannel = Pick<ManagementChannel, 'code' | 'description' | 'icon' | 'name'> & {
  active?: boolean
  isOther?: boolean
}

export type NewChannelForm = {
  description: string
  icon?: NonNullable<JourneyChannel['icon']>
  name: string
  published?: boolean
}

export type EditChannelForm = Pick<ManagementChannel, 'name' | 'description' | 'icon'>

export type ChannelsFilter = {
  hidden?: boolean
  visible?: boolean
}

export type ChannelToDelete = {
  id: number
  name?: string
}

export interface EditChannelModalForm {
  desc?: string
  name?: string
}

export interface ManageChannelsCheckboxes {
  custom: boolean
  global: boolean
  local: boolean
  search: string
}

export type LocalChannelForm = Pick<ManagementChannel, 'description' | 'icon' | 'name'>

export type MergeActionType = 'update' | 'remove'

export type ChannelCodeTypeMap = Record<string, ChannelEnum | undefined>
