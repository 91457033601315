import { PersonaAction } from '@dis/modules/src/journey/header/Personas'
import { SectorUnion } from '@dis/modules/src/ConnectedCapabilities/constants'
import { JourneySubscribe } from '@dis/modules/src/journey/grid/types'
import { WsMessageModel, WsOperationType } from './wsModels'
import { Language } from './GeneralTypes'
import { BacklogFormField, TenantLimitsForm } from './forms'
import { Journeys } from './FolderTypes'
import { WsChangeDataModel } from './wsChangeDataModels'
import { JourneyChannel } from './ChannelTypes'
import { GroupsManagementAccessLevel } from './GroupsManagement'
import { AdministrationTenant } from './TenantTypes'

export enum WsChangeOperation {
  Delete = 'delete',
  Insert = 'insert',
  Update = 'update',
}

export type WsChangeUserPreferencesMessageModel = {
  [WsOperationType.Change]: {
    data: {
      locale: Language
    }
    model: WsMessageModel.UserPreference
  }
  tenantId: number
}

export type WsChangeKpmgTableOfUsers = {
  [WsOperationType.Change]: {
    data: { id: number; role?: string; tenants?: Array<{ tenantId: number; use: boolean }> }
    id?: string
    model: WsMessageModel.KpmgUser
  }
  tenantId: number
}

export type WsChangePersonaColumnValueMessageModel = {
  [WsOperationType.Change]: {
    data: Array<{
      operation: WsChangeOperation
      path?: string
      value?:
        | {
            guid: string
            model: string
            position: number
            title: string
            value: string
          }
        | {
            desc?: string
            image?: number
            name: string
          }
    }>
    id?: string
    model: WsMessageModel.Persona
  }
  tenantId: number
}

export type WsChangeAtlasFolderJourney = {
  [WsOperationType.Change]: {
    data: { documents: []; folders: []; id?: string; name?: string }
    id?: string
    model: WsMessageModel.Atlas | WsMessageModel.Folder
  }
  tenantId: number
}

export type WsChangeTableOfBacklog = {
  [WsOperationType.Change]: {
    data: BacklogFormField
    id?: string
    model: WsMessageModel.UserStory
  }
  tenantId: number
}

export type WsChangeChannels = {
  [WsOperationType.Change]: {
    data: {
      channels: JourneyChannel[]
      id?: string
    }
    id?: string
    model: WsMessageModel.TenantChannels
  }
  tenantId: number
}

export type WsChangeChannel = {
  [WsOperationType.Change]: {
    data: {
      description: string
      icon: string
      id: number
      name: string
    }
    id?: string
    model: WsMessageModel.Channel
  }
  tenantId: number
}

export type WsChangeCapability = {
  [WsOperationType.Change]: {
    data: {
      desc: string
      isPublic?: boolean
      lang: string
      name: string
      sectorCode: SectorUnion
    }
    id: string
    model: WsMessageModel.Capability | WsMessageModel.SubCapability
  }
  tenantId: number
}

export type WsPublishSubcapability = {
  [WsOperationType.Change]: {
    id: string
    model: WsMessageModel.SubCapabilityPublic
  }
  tenantId: number
}

export type WsChangeFolderHeader = {
  [WsOperationType.Change]: {
    data: {
      atlasid?: string
      documents?: Journeys[]
      id?: string
      name?: string
    }
    id?: string
    model: WsMessageModel.Folder
  }
  tenantId: number
}

export type WsChangePublicationStatus = {
  [WsOperationType.Change]: {
    data: [
      {
        operation: WsChangeOperation
        path: string
        value: JourneySubscribe['info']
      },
    ]
    id: string
    model: WsMessageModel.Document
  }
  tenantId: number
}

export type WsChangeTableOfUsersManagement = {
  [WsOperationType.Change]: {
    data: {
      groups?: Array<{
        groupId: number
        use: boolean
      }>
      id: number
      role?: string
    }
    id: string
    model: WsMessageModel.User
  }
  tenantId: number
}

export type WsChangeJourneyGridProvider = {
  [WsOperationType.Change]: {
    data?: WsChangeDataModel
    id?: string
    model: WsMessageModel.Document
  }
  tenantId: number
}

export type WsChangePersonas = {
  [WsOperationType.Change]: {
    data?: {
      operation?: PersonaAction
      path?: string
      value?: { desc: string; id: string; image: string; model: string; name: string }
    }[]
    id?: string
    model: WsMessageModel.Document
  }
  tenantId: number
}

export type WsChangeHandlerForWYSIWYG = {
  [WsOperationType.Change]: {
    data: {
      operation: WsChangeOperation
      path: string
      value: {
        desc?: string
        image?: string
        title?: string
        value?: string
      }
    }[]
    id?: string
    model: WsMessageModel.Persona
  }
  tenantId: number
}

export type WsChangeAtlasDetail = {
  [WsOperationType.Change]: {
    data: { documents: []; folders: []; id?: string; name?: string }
    id?: string
    model: WsMessageModel.Atlas
  }
  tenantId: number
}

export type WsChangeTenantMGMT = {
  [WsOperationType.Change]: {
    data: AdministrationTenant
    id?: string
    model: WsMessageModel.Tenant
  }
  tenantId: number
}

export type WsChangeGroupsMGMTname = {
  [WsOperationType.Change]: {
    data: {
      groupId: number
      name: string
    }
    id: string
    model: WsMessageModel.Group
  }
  tenantId: number
}

export type WsChangeGroupsMGMTatlas = {
  [WsOperationType.Change]: {
    data: {
      groupId: number
      items: Array<{
        accessLevel?: GroupsManagementAccessLevel
        atlasId: number
      }>
    }
    id: string
    model: WsMessageModel.AtlasGroup
  }
  tenantId: number
}

export type WsChangeTenantLimits = {
  [WsOperationType.Change]: {
    data: TenantLimitsForm
    id?: string
    model: WsMessageModel.Tenant
  }
  tenantId: number
}

export type WsChangeTenant = {
  [WsOperationType.Change]: {
    data: {
      description?: string
      name: string
    }
    id?: number
    model: WsMessageModel.TenantList
  }
  tenantId: number
}

export type WsChangeCategory = {
  [WsOperationType.Change]: {
    data: {
      name: string
    }
    id?: string
    model: WsMessageModel.Category
  }
  tenantId: number
}

export type WsChangeTemplateDocument = {
  [WsOperationType.Change]: {
    data: {
      categoryId?: number
      desc?: string
      name: string
      published: boolean
    }
    id?: string
    model: WsMessageModel.GlobalTemplateDocument | WsMessageModel.LocalTemplateDocument
  }
  tenantId: number
}

export type WsChangeChannelVisibility = {
  [WsOperationType.Change]: {
    data: {
      id: number
      visible: boolean
    }
    id?: string
    model: WsMessageModel.ChannelVisibility
  }
  tenantId: number
}

export type WsChangeMessageModel = {
  useInternalErrorHandling?: boolean
} & (
  | WsChangeTenantLimits
  | WsChangeGroupsMGMTname
  | WsChangeGroupsMGMTatlas
  | WsChangeTenantMGMT
  | WsChangeAtlasDetail
  | WsChangeHandlerForWYSIWYG
  | WsChangePersonas
  | WsChangeJourneyGridProvider
  | WsChangeTableOfUsersManagement
  | WsChangePublicationStatus
  | WsChangeFolderHeader
  | WsChangeCapability
  | WsChangeChannels
  | WsChangeChannel
  | WsChangeTableOfBacklog
  | WsChangeAtlasFolderJourney
  | WsChangePersonaColumnValueMessageModel
  | WsChangeKpmgTableOfUsers
  | WsChangeUserPreferencesMessageModel
  | WsChangeTemplateDocument
  | WsChangeChannelVisibility
  | WsChangeTenant
  | WsPublishSubcapability
  | WsChangeCategory
)
