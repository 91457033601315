import { useCallback, useRef, useState } from 'react'
import {
  WsCreateMessageModel,
  WsEnhancedResponseMessage,
  WsErrorMessage,
  WsMessageModel,
} from '@dis/types/src/wsModels'
import { IdModelData } from '@dis/types/src/CreateTypes'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import {
  selectSelectedIsTemplateTenant,
  selectSelectedTenantId,
} from '@dis/redux/src/tenants/tenantsSelectors'
import {
  BacklogFormField,
  SaveOrEditTemplateForm,
  NewPersonaForm,
  CreateOrEditTenantForm,
  CreateOrEditCategoriesForm,
} from '@dis/types/src/forms'
import { selectLanguage } from '@dis/redux/src/user/selectors'
import { NewChannelForm } from '@dis/types/src/ChannelTypes'
import {
  WsCreateFolder,
  WsCreateGroup,
  WsCreateKpmgUser,
  WsCreateTemplateDocument,
  WsCreateTenantUser,
  WsCreateUserResendTicket,
} from '@dis/types/src/wsCreateDataModels'
import { UserRole, UserType, UserTypeEnum } from '@dis/types/src/UsersAndRoles'
import { Api } from '@dis/api'
import { PersonaDetailType } from '@dis/types/src/PersonaTypes'

type Props<D = IdModelData | undefined> = {
  onData?: (data: D) => void
  onError?: (error: WsErrorMessage['error']) => void
  useInternalErrorHandling?: boolean
}

export const useCreate = <D = IdModelData | undefined>(props?: Props<D>) => {
  const [createData, setCreateData] = useState<D>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<WsErrorMessage['error']>()

  const onDataWrapper = useRef(props?.onData)
  onDataWrapper.current = props?.onData

  const onErrorWrapper = useRef(props?.onError)
  onErrorWrapper.current = props?.onError

  const useInternalErrorHandling = useRef(props?.useInternalErrorHandling)
  useInternalErrorHandling.current = props?.useInternalErrorHandling

  const onMessage = useCallback((newData: D, error?: WsErrorMessage['error']) => {
    if (error) {
      setLoading(false)
      setError(error)
      onErrorWrapper.current?.(error)
    } else {
      setCreateData(newData)
      setLoading(false)
      onDataWrapper.current?.(newData)
    }
  }, [])

  const sendWrapper = useCallback(
    (message: WsCreateMessageModel) => {
      setLoading(true)
      setError(undefined)
      setCreateData(undefined)
      Api.sendCreateMessage(
        {
          ...message,
          useInternalErrorHandling: useInternalErrorHandling.current,
        },
        onMessage,
      )
    },
    [onMessage],
  )

  const clearError = useCallback(() => {
    setError(undefined)
  }, [setError])

  return {
    clearError,
    createData,
    error,
    loading,
    sendWrapper,
  }
}

type UseCreateBacklogItemType = {
  estimation?: number
  rest: {
    criterium?: string
    description: string
    name: string
    priority: number
    status: number
  }
}

export const useCreateBacklogItem = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, createData, error } = useCreate(props)

  const sendCreate = useCallback(
    ({ estimation, rest }: UseCreateBacklogItemType) => {
      if (tenantId) {
        const message: WsCreateMessageModel = {
          create: {
            data: {
              ...rest,
              estimation: Number(estimation),
            },
            model: WsMessageModel.UserStory,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateChannel = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, createData, error } = useCreate(props)

  const sendCreate = useCallback(
    (data: NewChannelForm) => {
      if (tenantId) {
        const message: WsCreateMessageModel = {
          create: {
            data: data,
            model: WsMessageModel.Channel,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateJourney = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, createData, error } = useCreate(props)

  const sendCreate = useCallback(
    ({ atlasId, folderId }: { atlasId?: number; folderId?: number }) => {
      if (tenantId) {
        const message: WsCreateMessageModel = {
          create: {
            data: {
              atlasId: atlasId?.toString(),
              folderId: folderId?.toString(),
            },
            model: WsMessageModel.Document,
          },
          tenantId: tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateJourneyBacklogItem = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, createData, error } = useCreate(props)

  const sendCreate = useCallback(
    (formData: Omit<BacklogFormField, 'id'>) => {
      if (tenantId) {
        const message: WsCreateMessageModel = {
          create: {
            data: {
              criterium: formData.criterium,
              description: formData.description,
              estimation: Number(formData.estimation),
              name: formData.name,
              priority: formData.priority,
              status: formData.status,
            },
            model: WsMessageModel.UserStory,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateJourneySubcapability = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)
  const userLanguage = useAppSelector(selectLanguage)

  const { sendWrapper, loading, createData, error } = useCreate(props)

  const sendCreate = useCallback(
    ({ capabilityId, name }: { capabilityId: number; name?: string }) => {
      if (name && tenantId) {
        const message: WsCreateMessageModel = {
          create: {
            data: {
              capabilityId: capabilityId.toString(),
              lang: userLanguage,
              name: name,
            },
            model: WsMessageModel.SubCapability,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId, userLanguage],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateTenant = (props?: Props) => {
  const { sendWrapper, loading, createData, error } = useCreate(props)

  const sendCreate = useCallback(
    (data: CreateOrEditTenantForm) => {
      const message: WsCreateMessageModel = {
        create: {
          data: { description: data?.description, name: data.tenantName },
          model: WsMessageModel.Tenant,
        },
        tenantId: 0,
      }

      sendWrapper(message)
    },
    [sendWrapper],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

type UseCreatePersonaProps = {
  onData?: (data: PersonaDetailType) => void
}

export const useCreatePersona = (props?: UseCreatePersonaProps) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { onData: onDataProp } = props || {}

  const onData = useCallback(
    (data: WsEnhancedResponseMessage<PersonaDetailType>) => {
      dispatchedActions.personas.setNewPersona()

      if (data?.data) {
        onDataProp?.(data.data)
      }
    },
    [onDataProp],
  )

  const { sendWrapper, loading, error } = useCreate<WsEnhancedResponseMessage<PersonaDetailType>>({
    onData,
  })

  const sendCreate = useCallback(
    ({ name }: NewPersonaForm) => {
      if (tenantId) {
        const message: WsCreateMessageModel = {
          create: {
            data: {
              name,
            },
            model: WsMessageModel.Persona,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    error,
    loading,
    sendCreate,
  }
}

export const useCreateNewUser = (props?: Props) => {
  const { sendWrapper, loading, createData, error, clearError } = useCreate(props)

  const sendCreate = useCallback(
    ({
      formData: { email, role, tenantId },
      userType,
    }: {
      formData: { email: string; role: UserRole; tenantId: number }
      userType: UserType
    }) => {
      const message: WsCreateMessageModel<WsCreateTenantUser | WsCreateKpmgUser> = {
        create: {
          data: {
            email,
            role,
          },
          model: WsMessageModel.User,
        },
        tenantId: tenantId || 0,
      }

      switch (userType) {
        case 'kpmg': {
          const data: WsCreateKpmgUser = {
            email,
            role,
          }

          if (role === 'kpmguser') {
            data.tenants = [tenantId]
          }

          message.create.model = WsMessageModel.KpmgUser
          message.create.data = data
          message.tenantId = 0
          break
        }
        case 'tenant': {
          message.create.model = WsMessageModel.User
          message.tenantId = tenantId || 0
          break
        }
      }

      sendWrapper(message)
    },
    [sendWrapper],
  )

  return {
    clearError,
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateResendTicket = ({
  userType,
  forceTenantId,
  ...restProps
}: Props & { forceTenantId?: number; userType: UserTypeEnum }) => {
  const { sendWrapper, loading, createData, error } = useCreate(restProps)

  const actualTenantId = useAppSelector(selectSelectedTenantId)

  const tenantId = forceTenantId || actualTenantId

  const sendCreate = useCallback(
    (email: string) => {
      if (userType === UserTypeEnum.KpmgUser || tenantId) {
        const message: WsCreateMessageModel<WsCreateUserResendTicket> = {
          create: {
            data: {
              email,
            },
            model: WsMessageModel.ResendTicketSystem,
          },
          tenantId: tenantId || 0,
        }
        sendWrapper(message)
      } else {
        throw new Error('useCreateResendTicket - sendCreate: Missing tenant ID!')
      }
    },
    [sendWrapper, tenantId, userType],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateFolder = (props?: Props & { forceTenantId?: number }) => {
  const { sendWrapper, loading, createData, error } = useCreate(props)

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const tenantId = props?.forceTenantId ?? selectedTenantId

  const sendCreate = useCallback(
    (atlasId: number, name?: string) => {
      if (tenantId) {
        const message: WsCreateMessageModel<WsCreateFolder> = {
          create: {
            data: {
              atlasId: atlasId.toString(),
              name: name ?? undefined,
            },
            model: WsMessageModel.Folder,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateAtlas = (props?: Props & { forceTenantId?: number }) => {
  const { sendWrapper, loading, createData, error } = useCreate(props)

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const tenantId = props?.forceTenantId ?? selectedTenantId

  const sendCreate = useCallback(
    (atlasName?: string) => {
      if (tenantId) {
        const message: WsCreateMessageModel = {
          create: {
            data: { name: atlasName },
            model: WsMessageModel.Atlas,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateGroup = (props?: Props & { forceTenantId?: number }) => {
  const { sendWrapper, loading, createData, error } = useCreate(props)

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const tenantId = props?.forceTenantId ?? selectedTenantId

  const sendCreate = useCallback(
    (groupName: string) => {
      if (tenantId && groupName) {
        const message: WsCreateMessageModel<WsCreateGroup> = {
          create: {
            data: {
              name: groupName,
            },
            model: WsMessageModel.Group,
          },
          tenantId: tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateTemplateDocument = (props?: Props) => {
  const { sendWrapper, loading, createData, error } = useCreate(props)

  const isTemplate = useAppSelector(selectSelectedIsTemplateTenant)
  const tenantId = useAppSelector(selectSelectedTenantId)

  const templateModel = isTemplate
    ? WsMessageModel.GlobalTemplateDocument
    : WsMessageModel.LocalTemplateDocument

  const sendCreate = useCallback(
    (formData: SaveOrEditTemplateForm, journeyId: number) => {
      const message: WsCreateMessageModel<WsCreateTemplateDocument> = {
        create: {
          data: {
            categoryId: formData.categoryId,
            desc: formData.desc,
            journeyid: journeyId,
            name: formData.name,
            published: formData.published,
          },
          model: templateModel,
        },
        tenantId: tenantId || 0,
      }

      sendWrapper(message)
    },
    [sendWrapper, tenantId, templateModel],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}

export const useCreateCategory = (props?: Props) => {
  const { sendWrapper, loading, createData, error } = useCreate(props)

  const tenantId = useAppSelector(selectSelectedTenantId)

  const sendCreate = useCallback(
    (data: CreateOrEditCategoriesForm) => {
      if (tenantId) {
        const message: WsCreateMessageModel = {
          create: {
            data: { name: data.categoryName },
            model: WsMessageModel.Category,
          },
          tenantId: tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    createData,
    error,
    loading,
    sendCreate,
  }
}
